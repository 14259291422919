<template>
  <header id="principal">
    <div class="header">
        <div class="nav">
          <div class="btnMenuMob">
            <div class="icon-open" @click="mostrarMenu = true" v-show="mostrarMenu == false">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" />
              </svg>
            </div>
            <div class="icon-close " @click="mostrarMenu = false" v-show="mostrarMenu == true" >
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M21,15.61L19.59,17L14.58,12L19.59,7L21,8.39L17.44,12L21,15.61M3,6H16V8H3V6M3,13V11H13V13H3M3,18V16H16V18H3Z" />
              </svg>
            </div>
          </div>
            <div class="logo">
               <router-link to="/"> <img src="https://credix.com.mx/plantillav2/images/logo-6.png" alt=""></router-link>
            </div>
            <nav>
                <ul>
                    <li><router-link to="/nosotros">Nosotros</router-link></li>
                    <li><router-link to="/une">UNE</router-link></li>
                </ul>
            </nav>
        </div>
        <div class="login">
              <router-link to="/iniciar-sesion" class="entrar-btn registro">Portal Clientes</router-link>
              <router-link to="/registro-proveedor" class="entrar-btn registro">Portal Proveedores</router-link>
              <router-link to="/iniciar-sesion-proveedor" v-if="btnLogin == true">Entrar</router-link>
              <button  @click="checarLogin()" v-if="btnAdmin == true">
                <span class="mr-2">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M21.1,12.5L22.5,13.91L15.97,20.5L12.5,17L13.9,15.59L15.97,17.67L21.1,12.5M11,4A4,4 0 0,1 15,8A4,4 0 0,1 11,12A4,4 0 0,1 7,8A4,4 0 0,1 11,4M11,6A2,2 0 0,0 9,8A2,2 0 0,0 11,10A2,2 0 0,0 13,8A2,2 0 0,0 11,6M11,13C11.68,13 12.5,13.09 13.41,13.26L11.74,14.93L11,14.9C8.03,14.9 4.9,16.36 4.9,17V18.1H11.1L13,20H3V17C3,14.34 8.33,13 11,13Z" />
                  </svg>
                </span>
               {{BtnLabelusr}}
              </button>
               
        </div>
        
    </div>
    <Transition name="slide-fade">
    <div class="mobileMenu" v-show="mostrarMenu">
      <nav>
        <ul>
          <li  @click="mostrarMenu = false"><router-link  to="/#servicios"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M10,17L15,12L10,7V17Z" />
              </svg> Servicios</router-link></li>
                          <li @click="mostrarMenu = false"><router-link   to="/nosotros"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M10,17L15,12L10,7V17Z" />
              </svg> Nosotros</router-link></li>
                        <li  @click="mostrarMenu = false"><router-link  to="/une"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M10,17L15,12L10,7V17Z" />
              </svg> UNE</router-link></li>
              <li  @click="mostrarMenu = false"><router-link  to="/alta-clientes" class="entrar-btn registro"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M10,17L15,12L10,7V17Z" />
              </svg> Alta Clientes</router-link></li>
                        <li @click="mostrarMenu = false"><router-link   to="/registro-proveedor" class="entrar-btn registro"><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M10,17L15,12L10,7V17Z" />
              </svg> Alta Proveedor</router-link></li>
        </ul>
      </nav>
      <div class="redes-menu-mob">
        <p>Síguenos en nuestras redes:</p>
        <div>
          <a href="https://mx.linkedin.com/company/credixmx">
            <svg style="width:45px;height:45px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M19 3A2 2 0 0 1 21 5V19A2 2 0 0 1 19 21H5A2 2 0 0 1 3 19V5A2 2 0 0 1 5 3H19M18.5 18.5V13.2A3.26 3.26 0 0 0 15.24 9.94C14.39 9.94 13.4 10.46 12.92 11.24V10.13H10.13V18.5H12.92V13.57C12.92 12.8 13.54 12.17 14.31 12.17A1.4 1.4 0 0 1 15.71 13.57V18.5H18.5M6.88 8.56A1.68 1.68 0 0 0 8.56 6.88C8.56 5.95 7.81 5.19 6.88 5.19A1.69 1.69 0 0 0 5.19 6.88C5.19 7.81 5.95 8.56 6.88 8.56M8.27 18.5V10.13H5.5V18.5H8.27Z" />
            </svg>
          </a>
          <a href="https://www.credix.com.mx/#">
            <svg style="width:45px;height:45px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
            </svg>
          </a>
          <a href="https://es-la.facebook.com/Credix.mx/">
            <svg style="width:45px;height:45px" viewBox="0 0 24 24">
                <path fill="currentColor" d="M12 2.04C6.5 2.04 2 6.53 2 12.06C2 17.06 5.66 21.21 10.44 21.96V14.96H7.9V12.06H10.44V9.85C10.44 7.34 11.93 5.96 14.22 5.96C15.31 5.96 16.45 6.15 16.45 6.15V8.62H15.19C13.95 8.62 13.56 9.39 13.56 10.18V12.06H16.34L15.89 14.96H13.56V21.96A10 10 0 0 0 22 12.06C22 6.53 17.5 2.04 12 2.04Z" />
            </svg>
          </a>
        </div>

      </div>
    </div>
    </Transition>
  </header>
</template>

<script>

export default {
  mounted:function(){
    /* if(localStorage.getItem("regApp")){
        this.User = JSON.parse(atob(localStorage.getItem("regApp")))
        //console.log(this.User)
        this.BtnLabelusr = this.User.name
        this.btnLogin = false;
        this.btnAdmin = true;
    }*/
  },
  methods:{
      checarLogin(){
        //console.log("iniciando")
        if(this.User.usr){
          var gapp = (this.$route.query.app)?this.$route.query.app:""
          var pcdx = (this.$route.query.pcdx)?"&pcdx="+this.$route.query.pcdx:""
          var reply = (this.$route.query.reply)?"&reply="+this.$route.query.reply:""
          location.href = "validacion.php?app="+gapp+pcdx+reply
        }
      }
  },
  data(){
    return{
        mostrarMenu:false,
        btnLogin:true,
        btnAdmin:false,
        BtnLabelusr:"",
        User:[]
    }
  }
}
</script>

<style>
header#principal {
  --tw-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
header#principal .header {
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
  margin-right: 2rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
}
header#principal .header .logo img {
  width: 150px;
}
header#principal .header .nav {
  display: flex;
  align-items: center;
}
header#principal .header .nav li:hover a {
  color: #d00525;
}
header#principal .header .nav a {
  text-decoration: none;
  color: #006699;
  padding: 5px 10px;
  font-size: 16px;
  letter-spacing: 0.05em;
  font-weight: 500;
  transition: all 0.2s ease-out;
}
header#principal .header nav ul {
  display: flex;
  flex: 1 1 0%;
  align-items: center;
  list-style: none;
  margin-left: 2rem;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
}
header#principal .header .login {
  display: flex;
  justify-content: center;
  align-items: center;
}
header#principal .header .login a,header#principal .header .login button {
  text-decoration: none;
  background-color: #006699;
  color: #ffffff;
  padding: 5px 25px;
  font-size: 13px;
  letter-spacing: 0.05em;
  font-weight: 400;
  border-radius: 10px;
  margin-left: 10px;
}
header#principal .header .login button{
  border: none;
  outline: none;
}

header#principal .header .login .entrar-btn {
  border: 0.4px solid #006699;
  background-color: transparent;
  color: #006699;
}
.btnMenuMob {
  display: none;
}
.mobileMenu {
  height: 93vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mobileMenu nav {
  padding: 2rem 1rem;
}
.mobileMenu nav ul {
  list-style: none;
}
.mobileMenu nav ul li {
  padding: 0.5rem 0;
}
.mobileMenu nav ul li a {
  font-size: 15pt;
  text-decoration: none;
  color: #006699;
}
.mobileMenu .icon-close {
  transform: rotateY(-180deg);
}
.mobileMenu .redes-menu-mob {
  text-align: right;
  padding-right: 3rem;
  padding-bottom: 3rem;
}
.mobileMenu .redes-menu-mob p {
  font-size: 13pt;
  color: #006699;
}
.mobileMenu .redes-menu-mob a {
  color: #006699;
  margin-left: 0.5rem;
}
.mobileMenu .redes-menu-mob a svg {
  width: 50px;
}
@media only screen and (max-width: 768px) {
  .btnMenuMob {
    color: #006699;
    display: block;
  }
  header .header {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  header .header .nav nav {
    display: none;
  }
  header .header .nav nav.active {
    display: flex;
  }
  header .header .login .registro {
    display: none;
  }
}
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter-from, .slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

</style>