<template>
    <section>
        <div class="cargando" v-show="cargando">
            <div class="spinner" textosP="2">
                <Loading/>
            </div>
        </div>
        <div class="container" v-show="!cargando">
            <div class="row textosArriba">
                <div class="col-md-6">
                    <div class="titulos">
                        <h1>Comienza tu solicitud<b> ahora</b> </h1>
                        <h5> Obtén un crédito con garantía inmobiliaria por <span>hasta el 50% del valor</span> de tu casa, departamento, oficina o terreno. <sup>1</sup>  </h5> 
                        <h5>Utiliza tu bien inmueble como garantía y obtén liquidez para: </h5>
                        <div class="ps-3 mt-4 bull">
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Unificar tus deudas</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                            <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                              </svg> Capital de trabajo</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                    </svg> Aprovechar oportunidades para tu negocio</p>
                                <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
                                </svg> Hacer realidad proyecto que tienes en mente</p>
                        </div>
                        <div class="sups mt-5">
                            <p>
                                <sup>1</sup>Sujeto a aprobación y propuesta del comité de crédito.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="sec-form">
                        
                        <div class="form" >
                            <form @submit.prevent="mandarForm">
                                <div class="grupoinput">
                                        <label>
                                            <select v-model="mTipoCredito">
                                                <option value="null"><span>Tipo de crédito</span></option>
                                                <option value="Simple">Simple</option>
                                                <option value="Revolvente">Revolvente</option>
                                                
                                                <option value="Construcredix">Construcredix</option>
                                            </select>
                                            <span>Escoje el crédito que se acomode a ti</span>
                                        </label>
                                    </div>
                            <div class="cantidad">
                                     <small>Selecciona o escribe el</small>
                                <div class="cantidad-header">
                                    <h4>Monto Deseado:</h4>
                                    <money class="cantidad-input-label" v-model="mCantidad" v-bind="money" ></money>
                                </div>
                                <div class="slider">
                                    <input type="range" class="rangoinp" min="500000" max="50000000" step="100000" v-model="mCantidad" @change="cambiarMonto">
                                </div>
                               
                                <div class="btnsim">
                                  <p @click="showSimluador()">{{textoBtnSim}}</p>
                                </div>
                            </div>
                            <div v-if="quieroSimular" class="simulador">
                                <div v-if="formSim">
                                  <div class="cantidad">
                                  <div class="cantidad-header">
                                      <h4>Plazo:</h4>
                                      <money class="cantidad-input-label" v-model="mMeses" v-bind="meses" ></money>
                                  </div>
                                  <div class="slider">
                                      <input type="range" class="rangoinp" min="6" max="36" step="6" v-model="mMeses">
                                  </div>
                                  </div>
                                  <div class="mt-4 mb-4">
                                      <label>¿Cómo es tu historial crediticio?</label>
                                      <div class="d-flex cuentas" >
                                          
                                          <div class="d-flex radios">
                                              <input id="bueno" type="radio" v-model="mInteres" value="1">
                                              <label for="bueno">Muy bueno</label>
                                          </div>
                                          <div class="d-flex radios">
                                              <input id="Regular" type="radio" v-model="mInteres" value="2">
                                              <label for="Regular">Regular</label>
                                          </div>
                                          <div class="d-flex radios">
                                              <input id="Malo" type="radio" v-model="mInteres" value="3">
                                              <label for="Malo">Malo</label>
                                          </div>
                                      </div>
                                  </div>
                                   <div class="errores">
                                  <p>{{errores}}</p>
                                </div>
                                  <div class="grupuinput">
                                      <button type="button" @click="simular">Simular</button>
                                  </div>
                                </div>
                                  <div v-if="simulando">
                                      <LoadPuntos/>
                                  </div>
                                  <div  v-if="showSim">
                                      <h2 class="color-azul " style="font-weight: 300;font-size:20px ;">El resultado de tu simulación es:</h2>
                                      <div class="resultado mt-4">
                                          <p>Monto Solicitado <b>${{formatPrice(mCantidad)}} MXN</b></p>
                                          <p>Tasa variable de interés <b>{{tiv}}%</b> al {{fechaActual}}</p>
                                          <div class="sups">
                                              <p>
                                                Considerando el valor de la TIIE publicada por el Banco de México a la fecha más {{mInteres}} puntos porcentuales
                                              </p>
                                          </div>
                                          <p>Plazo de <b>{{mMeses}} Meses</b></p>
                                          <!-- <p v-if="resDep == 1">Pagarías <b>${{resultado}}</b> por mes<sup>1</sup></p> -->
                                          <p v-if="resDep == 1">Pago aproximado mensual de <b>${{interesMensual}}</b></p>
                                          <!-- <p v-if="resDep == 2">Pagarías <b>${{interesFinal}} </b>de intereses por mes<sup>1</sup></p> -->
                                          <p v-if="resDep == 2">Pago aproximado mensual de <b>${{interesMensual}} </b></p>
                                          <p>Comisión por apertura <b>6%</b></p>
                                          <p>Comisión anual <b>3%</b></p>
                                          <p>CAT <b>{{cat}}%</b> sin IVA al {{fechaActual}}</p>
                                          <p style="display:none">DETALLES: <b>{{detalleCalculo}}</b></p>
                                      </div>
                                      <!-- <div class="sups">
                                          <p>
                                              <sup>1</sup> Sujeto a aprobación y propuesta del comité de crédito.
                                          </p>
                                      </div> -->

                                      <div class="sups">
                                          <p>
                                              <sup>1</sup> Crédito sujeto a otros gastos de contratación.
                                          </p>
                                      </div>

                                      <div class="btnsim">
                                        <p @click="volverSimular()">Nueva simulación</p>
                                      </div>
                                  </div>
                            </div>
                           
                            <div class="grupoinput">
                                <label>
                                    <input id="fname" type="text" placeholder="Nombre completo" v-model="mNombre" autocomplete="off" >
                                    <span>Nombre completo</span>
                                </label>
                            </div>
                            <div class="grupoinput">
                                <label>
                                    <input id="fname" type="email" placeholder="Correo electrónico" v-model="mCorreo" autocomplete="off" >
                                    <span>Correo electrónico</span>
                                </label>
                            </div>
                            <div class="grupoinput">
                                <label>
                                    <input id="fname" type="text" placeholder="Télefono móvil" v-mask="' (###) - ### - ####'" autocomplete="off" v-model="mTel" >
                                    <span>Télefono móvil</span>
                                </label>
                            </div>
                            <div class="mb-4">
                                <div class="d-flex cuentas" >
                                    <label>¿Cuentas con una garantía inmobiliaria?</label>
                                    <div class="d-flex radios">
                                        <input type="radio" v-model="mCheckGarnatia" value="si">
                                        <label for="">Si</label>
                                    </div>
                                    <div class="d-flex radios">
                                        <input type="radio" v-model="mCheckGarnatia" value="no">
                                        <label for="">No</label>
                                    </div>
                                </div>
                            </div>
                            <div class="grupoinput" v-if="mCheckGarnatia == 'si'">
                                <label>
                                    <select v-model="mGarantia">
                                        <option value="" selected="selected">¿Cómo es tu inmueble?</option>
                                        <option value="1">Casa habitación</option>
                                        <option value="2">Departamento</option>
                                        <option value="3">Edificio</option>
                                        <option value="4">Local</option>
                                        <option value="5">Terreno</option>
                                        <option value="6">Otro</option>
                                    </select>
                                    <span>Tipo de garantía</span>
                                </label>
                            </div>
                            <div class="grupoinput" v-if="mCheckGarnatia == 'si'">
                                <label>
                                    <select v-model="mPertenece">
                                        <option value="" selected="selected">¿A quién le pertenece la garantía?</option>
                                        <option value="Propia">Propia</option>
                                        <option value="Socio">A un socio</option>
                                        <option value="Familiar">A un familiar</option>
                                        <option value="Otro">Otro</option>
                                    </select>
                                    <span>Tipo de garantía</span>
                                </label>
                            </div>
                            <div class="grupuinput">
                                <button :disabled="!isComplete">Continuar</button>
                            </div>
                            <div class="terminos">
                                <p>Al dar clic en CONTINUAR aceptas nuestro <router-link to="/aviso-de-privacidad">Aviso de privacidad</router-link> , y utilizar medios electrónicos de identificación, tales como el código de verificación.</p>
                            </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="titulos2">
                        <h5> Obtén un crédito con garantía inmobiliaria por <span>hasta el 50% del valor</span> de tu casa, departamento, oficina o terreno. <sup>1</sup>  </h5> 
                        <h5>Utiliza tu bien inmueble como garantía y obtén liquidez para: </h5>
                        <div class="ps-3 bull">
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Unificar tus deudas</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Capital de trabajo</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Aprovechar oportunidades para tu negocio</p>
                            <p><svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M14,2A8,8 0 0,0 6,10A8,8 0 0,0 14,18A8,8 0 0,0 22,10H20C20,13.32 17.32,16 14,16A6,6 0 0,1 8,10A6,6 0 0,1 14,4C14.43,4 14.86,4.05 15.27,4.14L16.88,2.54C15.96,2.18 15,2 14,2M20.59,3.58L14,10.17L11.62,7.79L10.21,9.21L14,13L22,5M4.93,5.82C3.08,7.34 2,9.61 2,12A8,8 0 0,0 10,20C10.64,20 11.27,19.92 11.88,19.77C10.12,19.38 8.5,18.5 7.17,17.29C5.22,16.25 4,14.21 4,12C4,11.7 4.03,11.41 4.07,11.11C4.03,10.74 4,10.37 4,10C4,8.56 4.32,7.13 4.93,5.82Z" />
</svg> Hacer realidad proyecto que tienes en mente</p>
                        </div>
                        <div class="sups">
                            <p>
                                <sup>1</sup>Sujeto a aprobación y propuesta del comité de crédito.
                            </p>
                        </div>
                    </div>
                </div>
                
            </div>
            
            
        </div>
        
       
    </section>

</template>
<script>
import Loading from '@/components/Loading/financiamiento.vue'
import LoadPuntos from '@/components/Loading/puntos.vue'
import axios from 'axios'
import {Money} from 'v-money'
export default {
    components:{
      Loading,Money,LoadPuntos
    },
  data() {
    return {
      mCantidad: 2000000,
      quieroSimular:false,
      mNombre:null,
      simulando:false,
      mRfc:'',
      mMeses:12,
      resultado:0,
      interesFinal:0,
      mInteres:0,
      textoBtnSim:"Simular pagos",
      mCorreo:'',
      mTel:'',
      resDep:0,
      meses: {
        prefix: '',
        suffix: '  Meses',
        precision: 0,
        masked: false
      },
      errors:[],
      errores:"",
      mCheckGarnatia:null,
      cargando:false,
      mTipoCredito:"null",
      formSim:true,
      showSim:false,
      mGarantia:"",
      mPertenece:"",
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$ ',
        suffix: ' MXN',
        precision: 0,
        masked: false
      },
      fechaActual:"",
      tiv: 0,
      cat:0,
      detalleCalculo: '',
      interesMensual: 0,
    }
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    simular(){
        console.log("simular");
      
        if(this.mTipoCredito == "null" || this.mInteres == 0){
          this.errores = "Por favor selecciona:";
          if(this.mInteres == 0){
            this.errores += " tipo de historial crediticio.";
          }
          if(this.mTipoCredito == "null"){
            this.errores += " tipo de crédito";
          }
        }else{
          // const nIntereses = [0,0.23,0.27,0.30]; // Valores anteriores
          const nIntereses = [0,20,22,25]; // PUNTOS de acuerdo a su historial crediticio
          this.mInteres = nIntereses[this.mInteres]
          this.formSim = false
          this.simulando = true
          setTimeout(async () => {
            this.simulando = false
            const deseado = this.mCantidad
            const meses = this.mMeses
            const interes = parseFloat(this.mInteres) / 12
            const aPagar = (deseado*interes)/(1-(Math.pow(1 + interes,-meses)))
            if(this.mTipoCredito == "Simple"){
              this.resDep =1
            }else{
              this.resDep =2
            }
            this.interesFinal = this.formatPrice(parseInt(aPagar - (deseado/meses)))
            this.showSim=true
            this.resultado = this.formatPrice(parseInt(aPagar))
            this.fechaActual = this.obtenerFechaActual();

            const tiie = await this.obtenerTIIE(); // Se saca con api de credix 
            const tiv = ((this.mInteres + parseFloat(tiie))/100).toFixed(4); // PUNTOS + TIIE

            const interesMensual = deseado * tiv/ 360 * 30;  // Monto * TIV/360*30
            const comisionApertura = deseado * 0.06;
            const comision3anio = meses > 24? deseado * 0.03 : 0;

            const primerPago = (deseado*-1) + comisionApertura;
            const pagoMensual = deseado * (tiv/12);
            const pago25 = pagoMensual + comision3anio; 
            const pagoFinal = pagoMensual + deseado;
            
            const flujosDeCaja = this.obtenerFlujosDeCaja(primerPago,pagoMensual,pago25,pagoFinal);
            console.log("flujosDeCaja", flujosDeCaja);
            const tir = this.calcularTIR(flujosDeCaja);
            const tirAnual = (tir * 12).toFixed(6);
            const CAT = this.calcularTasaEfectiva(tirAnual,12);
            console.log('La TIR calculada es:', tir);
            console.log("El CAT es:", CAT);

            // const flujosDeCaja = [-1000, 200, 300, 400, 500];
            // const tir = this.calcularTIR(flujosDeCaja);
            // console.log('La TIR calculada es:', tir);

            this.detalleCalculo = `
              puntos: ${this.mInteres}%<br>
              tiie: ${tiie}%<br>
              tiv: ${(tiv*100).toFixed(2)}%<br>
              interesMensual: $${this.formatPrice(interesMensual)} <br>
              comisionApertura: $${this.formatPrice(comisionApertura)} <br>
              comision3anio: $${this.formatPrice(comision3anio)} <br>
              primerPago: $${this.formatPrice(primerPago)} <br>
              pagoMensual: $${this.formatPrice(pagoMensual)} <br>
              pagoFinal: $${this.formatPrice(pagoFinal)} <br>
              pago25: $${this.formatPrice(pago25)} <br>
              tir: ${(tir*100).toFixed(2)}%<br>
              tirAnual: ${(tirAnual*100).toFixed(2)}%<br>
              CAT: ${(CAT*100).toFixed(2)}%<br>
            `;

            this.tiv = (tiv*100).toFixed(2);
            this.cat = (CAT*100).toFixed(1);
            this.interesMensual = this.formatPrice(interesMensual);
          }, 2000);  
        }
        
       
        
    },
    showSimluador(){
        console.log("showSimluador");
        if(this.quieroSimular == true){
          this.textoBtnSim = "Simular pagos"
          this.quieroSimular = false
        }else{
          this.textoBtnSim = "Cerrar simulador"
          this.quieroSimular = true
        }
    },
    volverSimular(){
      console.log("volverSimular")
      this.showSim = false
      this.formSim = true
    },
    getKey(){
      var crypto = require('crypto')
      var shasum = crypto.createHash('sha1')
      var dateObj = new Date();
      var month = String(dateObj.getMonth() + 1).padStart(2, '0');
      var day = String(dateObj.getDate()).padStart(2, '0');
      var year = dateObj.getUTCFullYear();
      var completeKey = "credix"+ year + month  + day;
      shasum.update(completeKey)
      let key = shasum.digest('hex')
      return key
    },
    mandarForm(e){
        
        window.scrollTo(0, 0);
        e.preventDefault();
        this.cargando = true
        if(this.mCheckGarnatia == "no"){
              setTimeout(() => {
                          this.$router.push('/solicitud/lo-sentimos');
                      }, 8000);   
                
              return
            }else{
              let solicitud = {
                nombre:this.mNombre,
                correo:this.mCorreo,
                telefono:this.mTel,
                garantia:this.mGarantia,
                pertenece:this.mPertenece,
                cantidad:this.mCantidad,
                tipo:this.mTipoCredito,
                prop:"organico",
                prop2:"Landing"
              } 
              axios.post('https://credix.grupotron-gs.com/api/prospecto-credito',solicitud)
              .then((res)=>{
                console.log(res)
                  if(res.data.code==200){
                    //this.gtag_report_conversion()
                    var formail = new FormData()
                    formail.append("key",this.getKey())
                    formail.append("correo",this.mCorreo)
                    formail.append("nombre",this.mNombre)
                    axios.post('https://credix.com.mx/plantillav2/funciones/enviarMail.php',formail)
                    .then(res=>console.log(res)).catch(err=>console.log(err))
                    setTimeout(() => {
                      this.$router.push('/solicitud/completado');
                    }, 5000);
                    
                  }
              })
              .catch((err) => {
                console.log(err)
                this.cargando = false
                alert("Error de conexión")
                })
            }
            
    },
    gtag_report_conversion(url) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
      this.gtag('event', 'conversion', {
          'send_to': 'AW-941920116/iQRaCPOCg2wQ9J6SwQM',
          'event_callback': callback
      });
      return false;
    },
    obtenerFechaActual() {
      const fecha = new Date(); // Creamos un objeto Date con la fecha y hora actuales
      const opcionesFecha = { day: '2-digit', month: '2-digit', year: 'numeric' }; // Opciones para el formato de fecha
      return fecha.toLocaleDateString('es-ES', opcionesFecha);  // Convertimos la fecha a una cadena en el formato local
    },
    async obtenerTIIE() {
      try {
        // Realizamos una llamada a una API ficticia para obtener la TIIE
        const response = await fetch('https://credix.com.mx/credixgs/api/front/tiie.php?token=54da947d9f4b3f6b91942f43b592be74');
        return await response.text();
      } catch (error) {
        console.error('Error al obtener la TIIE:', error);
      }
    },
     calcularTIR(flujosDeCaja) {
      // Implementación del método de Newton-Raphson para calcular la TIR
      const f = (tasa) => {
        return flujosDeCaja.reduce((sum, flujo, index) => sum + (flujo / Math.pow(1 + tasa, index)), 0);
      };
      const fDerivada = (tasa) => {
        return flujosDeCaja.reduce((sum, flujo, index) => sum + (-index * flujo / Math.pow(1 + tasa, index + 1)), 0);
      };
      const tolerancia = 0.0001; // Tolerancia para la convergencia
      let tasaInicial = 0.1; // Tasa inicial para el método
      let tasa = tasaInicial;
      let iteraciones = 0;
      do {
        const tasaAnterior = tasa;
        tasa = tasaAnterior - f(tasaAnterior) / fDerivada(tasaAnterior);
        iteraciones++;
        if (Math.abs(tasa - tasaAnterior) < tolerancia) {
          return tasa.toFixed(9); // Convertimos la TIR a porcentaje y redondeamos a 2 decimales
        }
      } while (iteraciones < 100000); // Evitar bucles infinitos
      console.error('No se pudo calcular la TIR después de 1000 iteraciones.');
      return null; // Retornamos null si no se pudo calcular la TIR
    },
    obtenerFlujosDeCaja(primerPago,pagoMensual,pago25,pagoFinal){
      let res = [primerPago];

      for (let i = 0; i < 24; i++) {
        res.push(pagoMensual);
      }

      res.push(pago25);

      for (let i = 0; i < 10; i++) {
        res.push(pagoMensual);
      }

      res.push(pagoFinal);

      return res;
    },
    calcularTasaEfectiva(tasaNominal, periodosCapitalizacion) {
        const tasaEfectiva = Math.pow(1 + (tasaNominal / periodosCapitalizacion), periodosCapitalizacion) - 1;
        return tasaEfectiva.toFixed(4);
    },
    cambiarMonto(){
      console.log("cambiarMonto");
      if(this.cat != 0)
        this.volverSimular();
    }
   },
   computed:{
        isComplete () {
            return (
                this.mCorreo != '' && this.mTel != '' && this.mNombre != '' && this.mCheckGarnatia != null
        )
    }
   },

   mounted(){            
    console.log("mounted");

        if(this.$route.query.nombre){ this.mNombre = this.$route.query.nombre}
        if(this.$route.query.credito){ this.mTipoCredito = this.$route.query.credito}
        if(this.$route.query.cantidad){ 
            let cantidad = this.$route.query.cantidad;
            cantidad=cantidad.replace("$","");
            cantidad=cantidad.replace(",","");
            cantidad=cantidad.replace(",","");
            cantidad=cantidad.replace(" ","");
            cantidad=cantidad.replace(" ","");
            console.log(cantidad);
            this.mCantidad = parseInt(cantidad)
        }
   }
}
</script>
<style lang="css">
.btnsim p{
  text-align: right;
  font-size: 12px;
  cursor: pointer;
  font-weight: 600;
  color:#006699;
  border-right: 0.7px solid #006699;
  padding-right: 5px;
}
.grupuinput button:disabled{
  cursor: not-allowed;
}
.textosArriba {
  margin-top: 1rem;
}
.textosArriba .titulos h1{
  width: 100%;
}
.sups p {
  color:#999;
  font-size: 7.5pt;
}
.cargando {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70vh;
}
.titulos2 {
  display: none;
}
.titulos {
  margin-top: 2rem;
}
.titulos sup {
  font-size: 9pt;
}
.titulos h1 {
  width: 70%;
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 0.5px;
  line-height: 52px;
  color: #006699;
}
.titulos h5 {
  font-size: 20px;
  font-weight: 300;
  letter-spacing: 0.5px;
  line-height: 28px;
  color: #201f2d;
}
.titulos h5 span {
  font-weight: bold;
  color: #006699;
}
.titulos .bull svg {
  color: #006699;
}
.sec-form {
  max-width: 500px;
  margin: auto;
  width: 100%;
  transition: all 0.3s;
  padding: 40px 40px 40px 40px;
  border-radius: 16px;
}
.form .cuentas label {
  font-size: 11pt;
}
.form .cuentas .radios {
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form .cuentas .radios label {
  margin-left: 5px;
  margin-bottom: 0;
}
.form .grupoinput {
  margin-bottom: 1.4rem;
}
.resultado p b{
    color:#006699;
}
.simulador{
  margin-bottom: 2rem;
}
.errores p {
 color:#006699;
}
.form .grupoinput label {
  position: relative;
  display: block;
  background-color: #edf0ef;
  border-radius: 16px;
}
.form .grupoinput label input, .form .grupoinput label select {
  font-size: 12px;
  box-sizing: border-box;
  display: block;
  border: none;
  padding: 20px;
  width: 95%;
  margin-bottom: 20px;
  background: none;
  outline: none;
  transition: all 0.2s ease-in-out;
}
.form .grupoinput label input.rfc, .form .grupoinput label select.rfc {
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.form .grupoinput label input::placeholder, .form .grupoinput label select::placeholder {
  transition: all 0.2s ease-in-out;
  color: #999;
  font-size: 15px;
}
.form .grupoinput label input:focus, .form .grupoinput label select:focus, .form .grupoinput label input.populated, .form .grupoinput label select.populated {
  padding-top: 28px;
  padding-bottom: 12px;
}
.form .grupoinput label input:focus::placeholder, .form .grupoinput label select:focus::placeholder, .form .grupoinput label input.populated::placeholder, .form .grupoinput label select.populated::placeholder {
  color: transparent;
}
.form .grupoinput label input:focus + span, .form .grupoinput label select:focus + span, .form .grupoinput label input.populated + span, .form .grupoinput label select.populated + span {
  opacity: 1;
  top: 10px;
}
.form .grupoinput label span {
  color: #006699;
  font: 13px Helvetica, Arial, sans-serif;
  position: absolute;
  top: 0px;
  left: 20px;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.form button {
  width: 100%;
  font-family: inherit;
  line-height: inherit;
  font-weight: 500;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity, box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 10px 14px;
  min-height: 40px;
  background-color: #006699;
  color: #fff;
  margin: 1rem 0;
  border-radius: 8px;
}
.form button:disabled {
  color: #999;
  background-color: #edf0ef;
}
.form .terminos p {
  font-size: 12px;
  text-align: justify;
  color: #999;
}
.form .terminos p a {
  text-decoration: none;
  color: #006699e3;
}
.cantidad {
  width: 100%;
}
.cantidad-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cantidad-header h4 {
  font-weight: 300;
  font-size: 12pt;
}
.cantidad-header p {
  font-size: 15pt;
  font-weight: 600;
  color: #006699;
}
.cantidad .rangoinp {
  width: 100%;
  margin-bottom: 1.5rem;
  -webkit-appearance: none;
  height: 3px;
  background: #7dc3e6;
}
.cantidad .rangoinp::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #006699;
  cursor: grab;
  background: #fff;
  box-shadow: 1px 1px 1px #006699;
}
.cantidad .rangoinp::-webkit-slider-thumb:active {
  cursor: grabbing;
}

@media only screen and (max-width: 768px) {
  .sec-form {
    padding: 0px 10px 40px 10px;
  }
  .textosArriba {
    margin-top: 0rem;
  }
  .textosArriba .titulos {
    margin-top: 1rem;
  }
  .textosArriba .titulos h1 {
    font-size: 20px;
    text-align: center;
    width: 100%;
  }
  .textosArriba .titulos h5, .textosArriba .titulos .bull, .textosArriba .titulos .sups {
    display: none;
  }
  .titulos2 {
    display: block;
    margin-bottom: 1rem;
  }
  .titulos2 sup {
    font-size: 9pt;
  }
  .titulos2 h5 {
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 28px;
    text-align: justify;
    color: #201f2d;
  }
  .titulos2 h5 span {
    font-weight: bold;
    color: #006699;
  }
  .titulos2 .bull {
    margin-top: 15px;
  }
  .titulos2 .bull p {
    font-size: 12px;
    margin-bottom: 0;
  }
  .titulos2 .bull svg {
    color: #006699;
  }
  .titulos2 .sups p {
    margin-top: 10px;
    font-size: 10px;
  }
}

</style>