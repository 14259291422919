<template>
<div>
<section class="header2" id="head2" v-scrollanimation>
    <div class="imgLogo">
     <a href="/"> <img src="https://credix.com.mx/plantillav2/images/logo-6.png" alt="Logo Credix" width="150"></a>
    </div> 
  </section>
  <section class="login-form">
    <div class="sec-rig"  v-scrollanimation>
        <div class="container-form" v-if="!iniciando">
          <form >
            <h1>Bienvenido/a</h1>
          <div class="grupo-input">
            <p>Ingresa correo ó RFC </p>
            <div class="input">
              <div class="icon">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M7.07,18.28C7.5,17.38 10.12,16.5 12,16.5C13.88,16.5 16.5,17.38 16.93,18.28C15.57,19.36 13.86,20 12,20C10.14,20 8.43,19.36 7.07,18.28M18.36,16.83C16.93,15.09 13.46,14.5 12,14.5C10.54,14.5 7.07,15.09 5.64,16.83C4.62,15.5 4,13.82 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,13.82 19.38,15.5 18.36,16.83M12,6C10.06,6 8.5,7.56 8.5,9.5C8.5,11.44 10.06,13 12,13C13.94,13 15.5,11.44 15.5,9.5C15.5,7.56 13.94,6 12,6M12,11A1.5,1.5 0 0,1 10.5,9.5A1.5,1.5 0 0,1 12,8A1.5,1.5 0 0,1 13.5,9.5A1.5,1.5 0 0,1 12,11Z" />
                </svg>  
              </div>
              <input type="text" v-model="usr">
            </div>
          </div>
          <div class="grupo-input">
            <p>Ingresa contraseña</p>
            <div class="input">
              <div class="icon">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M5.8 10C5.4 8.8 4.3 8 3 8C1.3 8 0 9.3 0 11S1.3 14 3 14C4.3 14 5.4 13.2 5.8 12H7V14H9V12H11V10H5.8M3 12C2.4 12 2 11.6 2 11S2.4 10 3 10 4 10.4 4 11 3.6 12 3 12M16 4C13.8 4 12 5.8 12 8S13.8 12 16 12 20 10.2 20 8 18.2 4 16 4M16 10.1C14.8 10.1 13.9 9.2 13.9 8C13.9 6.8 14.8 5.9 16 5.9C17.2 5.9 18.1 6.8 18.1 8S17.2 10.1 16 10.1M16 13C13.3 13 8 14.3 8 17V20H24V17C24 14.3 18.7 13 16 13M22.1 18.1H9.9V17C9.9 16.4 13 14.9 16 14.9C19 14.9 22.1 16.4 22.1 17V18.1Z" />
                </svg> 
              </div>
              <input id="inputPass" type="password" v-model="pass" >
                <svg id="showHidePass" @click="showHidePass()" style="width:24px;height:24px" viewBox="0 0 576 512">
                    <path fill="currentColor" d="M288 80c-65.2 0-118.8 29.6-159.9 67.7C89.6 183.5 63 226 49.4 256c13.6 30 40.2 72.5 78.6 108.3C169.2 402.4 222.8 432 288 432s118.8-29.6 159.9-67.7C486.4 328.5 513 286 526.6 256c-13.6-30-40.2-72.5-78.6-108.3C406.8 109.6 353.2 80 288 80zM95.4 112.6C142.5 68.8 207.2 32 288 32s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480s-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6zM288 336c44.2 0 80-35.8 80-80s-35.8-80-80-80c-.7 0-1.3 0-2 0c1.3 5.1 2 10.5 2 16c0 35.3-28.7 64-64 64c-5.5 0-10.9-.7-16-2c0 .7 0 1.3 0 2c0 44.2 35.8 80 80 80zm0-208a128 128 0 1 1 0 256 128 128 0 1 1 0-256z" />
                </svg> 
            </div>
          </div>
          <div class="errores" v-if="errorlogin">
            <p>Lo sentimos, el usuario o contraseña son incorrectos, intente nuevamente.</p>
          </div>
          <div class="errores" v-if="errores.length">
            <ul>
              <li v-for="(error,index) in errores" :key="index">{{ error }}</li>
            </ul>
          </div>
          <div class="grupo-input">
            <div class="forget">
              <router-link to="/recuperar-contrasena">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1M14.28,14.08L12.26,16.1C11.66,16.7 10.87,17 10.08,17C9.29,17 8.5,16.7 7.9,16.1C6.7,14.9 6.7,12.95 7.9,11.74L9.15,10.5L9.14,11.06C9.14,11.5 9.21,11.95 9.36,12.37L9.41,12.5L9.04,12.87C8.76,13.15 8.6,13.53 8.6,13.92C8.6,14.32 8.76,14.69 9.04,14.97C9.6,15.53 10.57,15.53 11.13,14.97L13.14,12.96C13.43,12.67 13.58,12.3 13.58,11.91C13.58,11.5 13.43,11.14 13.15,10.86C13,10.71 12.9,10.5 12.9,10.29C12.9,10.08 13,9.88 13.15,9.73C13.45,9.42 14,9.43 14.28,9.73C14.86,10.31 15.18,11.08 15.18,11.9C15.18,12.73 14.86,13.5 14.28,14.08M17.1,11.26L15.85,12.5L15.86,11.94C15.86,11.5 15.79,11.06 15.64,10.64L15.6,10.5L15.96,10.13C16.25,9.85 16.4,9.5 16.4,9.08C16.4,8.69 16.25,8.32 15.97,8.04C15.4,7.47 14.43,7.47 13.87,8.04L11.86,10.05C11.58,10.33 11.42,10.7 11.42,11.1C11.42,11.5 11.57,11.86 11.86,12.14C12,12.29 12.1,12.5 12.1,12.71C12.1,12.93 12,13.13 11.85,13.28C11.7,13.44 11.5,13.5 11.29,13.5C11.09,13.5 10.88,13.43 10.72,13.28C9.5,12.08 9.5,10.12 10.72,8.92L12.74,6.9C13.95,5.7 15.9,5.7 17.1,6.9C17.68,7.5 18,8.26 18,9.08C18,9.9 17.68,10.68 17.1,11.26Z" />
              </svg>
              <span class="ml-2">Olvidé mi contraseña</span> </router-link>
            </div>
          </div>
          <div class="grupo-input">
            <div class="forget">
              <router-link to="/registro-proveedor">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
    <path fill="currentColor" d="M19 17V19H7V17S7 13 13 13 19 17 19 17M16 8A3 3 0 1 0 13 11A3 3 0 0 0 16 8M19.2 13.06A5.6 5.6 0 0 1 21 17V19H24V17S24 13.55 19.2 13.06M18 5A2.91 2.91 0 0 0 17.11 5.14A5 5 0 0 1 17.11 10.86A2.91 2.91 0 0 0 18 11A3 3 0 0 0 18 5M8 10H5V7H3V10H0V12H3V15H5V12H8Z" />
</svg>
              <span class="ml-2">¿Aún no tienes cuenta? <b>crea una ahora.</b> </span> </router-link>
            </div>
          </div>
          <div class="grupo-input">
            <button class="btn-credix" @click="login()" type="button">
                <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M10 13C7.33 13 2 14.33 2 17V20H13.09C13.07 19.86 13.05 19.73 13.04 19.59C13 19.4 13 19.2 13 19C13 18.69 13.03 18.39 13.08 18.1C13.21 17.21 13.54 16.38 14 15.66C14.21 15.38 14.42 15.12 14.65 14.88L14.67 14.85C14.9 14.61 15.16 14.39 15.43 14.19C14.76 13.88 14 13.64 13.26 13.45C12.07 13.15 10.89 13 10 13M11.05 18.1H3.9V17C3.9 16.36 7.03 14.9 10 14.9C10.68 14.9 11.36 15 12 15.11C11.5 16 11.18 17 11.05 18.1M10 12C12.21 12 14 10.21 14 8S12.21 4 10 4 6 5.79 6 8 7.79 12 10 12M10 6C11.11 6 12 6.9 12 8S11.11 10 10 10 8 9.11 8 8 8.9 6 10 6M20 22V20H16V18H20V16L23 19L20 22Z" />
                </svg>
             &nbsp; Acceder</button>
          </div>
          </form>
          
        </div>
        <div class="container-form" v-if="iniciando">
          <Loading texto="espere un momento"/>
        </div>
      </div>
      <div class="sec-left">
       
      </div>
      
  </section>
</div>

</template>

<script>
import axios from "axios";
import Loading from '@/components/Loading/puntos2.vue' 
export default {
  
  methods:{
    
    login(){
      this.errores = []
      if(this.usr.length < 4 ){
        this.errores.push("Por favor escribe un correo o RFC válido.")
      }
      if(this.pass == ""){
        this.errores.push("Por favor escribe tu contraseña.")
      }
      if(this.errores.length == 0){
        var formLogin = new FormData()
        formLogin.append("strusr",this.usr)
        formLogin.append("strpw",this.pass)
        formLogin.append("key",this.getKey())
        
        axios.post("api/front/login.php", formLogin)
        .then((result) => {
          //console.log(result)
          if(result.data.code === 1 ){
            this.iniciando = true
            setTimeout(() => {
              let userCredixLogin = {
              name : result.data.name,
              url: result.data.url,
              usr: result.data.usr
            }
            const regApp = btoa(JSON.stringify(userCredixLogin))
            localStorage.setItem("regApp",regApp)
            location.href = result.data.url
            }, 2000);
            
          }else{
            this.errorlogin = true
          }
          
        })
        .catch(err =>console.log(err));
      }
      
    },
    getKey(){
      var crypto = require('crypto')
      var shasum = crypto.createHash('sha1')
      var dateObj = new Date();
      var month = String(dateObj.getMonth() + 1).padStart(2, '0');
      var day = String(dateObj.getDate()).padStart(2, '0');
      var year = dateObj.getUTCFullYear();
      var completeKey = "credix"+ year + month  + day;
      shasum.update(completeKey)
      let key = shasum.digest('hex')
      return key
    },
    haysesion(){
      axios.post('api/front/login.php')
      .then((res)=>{
        console.log(res)
      })
      .catch(err=>console.log(err))
    },
    showHidePass(){
      const icon =  document.querySelector("#inputPass");
      const type = icon.type;
      icon.type = type == "password" ? "text" :"password";
    }
  },
  mounted(){
    this.haysesion()
  },
  components:{
    Loading
  },
  data:()=>({
    usr:"",
    pass:"",
    retorno:[],
    errores:[],
    errorlogin:false,
    iniciando:false
  })
}
</script>

<style lang="css" scoped>
#showHidePass:hover{
  cursor:pointer;
}
.errores{
  background-color: #d0022150;
  color: #d00222;
  padding: 0.6rem 1rem;
  margin-bottom: 1rem;
  border-radius: 15px;
}
.errores p{
  margin: 0;
  font-size: 10pt;
}
.errores ul{
  list-style: none;
  margin-bottom: 0rem;
}
.errores ul li{
  font-size: 9pt;
}
.header2#head2 {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  width: 35%;
  padding: 0.5rem 1rem;
}
.header2#head2 .imgLogo {
  padding-left: 2rem;
}
.container-form {
  width: 400px;
  height: unset!important;
}
.container-form .input {
  border: 1px solid #4e4e4e;
  border-radius: 10px;
  padding: 10px 20px;
  display: flex;
}
.container-form input {
  margin-left: 20px;
  width: 100%;
  border: none;
}
.container-form input:focus-visible {
  border: none;
  outline: none;
}
.login-form {
  width: 100%;
  background: #006699;
  height: 100vh;
  display: flex;
}
.login-form .sec-left {
  width: 63%;
  height: 100%;
  color: #fff;
  background-image: url('~@/assets/estrella.webp');
  background-size: cover;
  background-position: center;
  background-color: #006699;
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  justify-content: center;
  align-items: center;
}
.login-form .sec-left .itemben {
  display: flex;
  margin: 1rem 0;
}
.login-form .sec-left .itemben h5 {
  margin-left: 20px;
  font-weight: lighter;
}
.login-form .sec-rig {
  width: 38%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-form .sec-rig h1 {
  color: #006699;
  font-weight: 300;
}
.grupo-input {
  margin-bottom: 1rem;
}
.grupo-input a {
  text-decoration: none;
  color: #006699;
}
.grupo-input p {
  margin-bottom: 5px;
}
.grupo-input .forget {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen 
  and (min-width: 768px)
  and (max-width: 1100px){
.login-form .sec-rig {
  width: 90%;
}
}
@media only screen and (max-width: 768px) {
  .header2#head2 {
    width: 100%;
    padding: 1rem 1rem;
    background: none;
    z-index: 99;
  }
  .header2#head2 .imgLogo {
    text-align: center;
    padding-left: 0rem;
  }
  .login-form {
    height: 100%;
    background-size: cover;
    background-image: url('~@/assets/estrella.webp');
  }
  .login-form .sec-left {
    display: none;
  }
  .login-form .sec-rig {
    width: 90%;
    height: 100vh;
    margin: auto auto;
    padding: 0 2rem;
  }
  .login-form .sec-rig .container-form {
    display: block;
  }
  .login-form .sec-rig .container-form .formulario {
    padding: 1.3rem 1.5rem;
    height: 100%;
    display: block;
  }
  .login-form .sec-rig .container-form .formulario .pasos {
    display: block;
    margin-bottom: 20rem;
    height: auto;
  }
  .login-form .sec-rig .container-form .formulario .pasos .paso.activo {
    height: 100%;
  }
  .login-form .sec-rig .container-form .footerform {
    padding: 10px 0;
    z-index: 999;
    position: fixed;
    bottom: 0px;
    display: block;
    left: 0;
    background-color: #fff;
    width: 100%;
  }
  .login-form .sec-rig .container-form .footerform .step {
    float: left;
    text-align: center;
    margin: 0;
    width: 25%;
  }
}

</style>