<template>
  <div>
    <section class="header3">
    <div class="imgLogo">
     <a href="/"> <img src="https://credix.com.mx/plantillav2/images/logo-6.png" alt="Logo Credix" width="150"></a>
    </div> 
  </section>
  <section class="form-prov">
      <div class="sec-left">
        <h1>Crea una cuenta</h1>
        <div class="beneficios-login py-4">
          <div class="itemben">
            <div class="icon">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </div>
            <h5>Recuerda que nosotros no pedimos pagos por adelantado.</h5>
          </div>
          <div class="itemben">
            <div class="icon">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </div>
            <h5>Trabajamos para brindarte un servicio de calidad.</h5>
          </div>
          <div class="itemben">
            <div class="icon">
              <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                  <path fill="currentColor" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
              </svg>
            </div>
            <h5>Si tienes dudas no dudes en contactarnos en nuestra sección de atención a clientes.</h5>
          </div>
        </div>
      </div>
      <div class="sec-rig">
        <div class="container-form">
          <div class="formulario">
              <div class="pasos">
                <div class="paso paso1"  :class="[(step > 1 && step !=1 ?  'despues':''),(step == 1 ? 'activo':'antes')]">
                  <h3 class="mb-5">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M12,4A4,4 0 0,1 16,8A4,4 0 0,1 12,12A4,4 0 0,1 8,8A4,4 0 0,1 12,4M12,6A2,2 0 0,0 10,8A2,2 0 0,0 12,10A2,2 0 0,0 14,8A2,2 0 0,0 12,6M12,13C14.67,13 20,14.33 20,17V20H4V17C4,14.33 9.33,13 12,13M12,14.9C9.03,14.9 5.9,16.36 5.9,17V18.1H18.1V17C18.1,16.36 14.97,14.9 12,14.9Z" />
                    </svg>
                     <span class="ms-3 ">Datos de Facturación</span> 
                  </h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa RFC</p>
                        <div class="input" :class="{'error' :errores.rfc}">
                          <input type="text" v-model="mrfc">
                        </div>
                        <div class="erroresf" v-if="errores.rfc">
                          <small>Ingresa un RFC válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa CURP</p>
                        <div class="input"  >
                          <input type="text" v-model="mcurp" >
                        </div>
                        
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa Razón Social</p>
                        <div class="input" :class="{'error' :errores.rs}">
                          <input type="text" v-model="mrs">
                        </div>
                        <div class="erroresf" v-if="errores.rs">
                          <small>Ingresa un razón social válida.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa Calle</p>
                        <div class="input" :class="{'error' :errores.calle}">
                          <input type="text" v-model="mcalle">
                        </div>
                        <div class="erroresf" v-if="errores.calle">
                          <small>Ingresa una calle válida.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa No. Exterior</p>
                        <div class="input"  :class="{'error' :errores.next}">
                          <input type="text" v-mask="'#######'" v-model="mnext">
                        </div>
                        <div class="erroresf" v-if="errores.next">
                          <small>Ingresa tu número exterior válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa No. Interior</p>
                        <div class="input" >
                          <input type="text" >
                        </div>
                        
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa Código Postal</p>
                        <div class="input" :class="{'error' :errores.cp}">
                          <input type="text" v-mask="'#####'" v-model="mcp">
                        </div>
                        <div class="erroresf" v-if="errores.cp">
                          <small>Ingresa tu código postal válido.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa Localidad</p>
                        <div class="input" :class="{'error' :errores.loc}">
                          <input type="text" v-model="mlocalidad">
                        </div>
                        <div class="erroresf" v-if="errores.loc">
                          <small>Ingresa una localidad válida.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa Municipio</p>
                        <div class="input" :class="{'error' :errores.muni}">
                          <input type="text" v-model="mmuni">
                        </div>
                        <div class="erroresf" v-if="errores.muni">
                          <small>Ingresa un municipio válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa Estado</p>
                        <div class="input" :class="{'error' :errores.estado}">
                          <input type="text" v-model="mestado" >
                        </div>
                        <div class="erroresf" v-if="errores.estado">
                          <small>Ingresa un estado válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="grupo-input">
                        <p>Ingresa País</p>
                        <div class="input" :class="{'error' :errores.pais}">
                          <input type="text" v-model="mpais">
                        </div>
                        <div class="erroresf" v-if="errores.pais">
                          <small>Ingresa un país válido.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="paso paso2"  :class="[(step > 2 && step !=2 ?  'despues':''),(step == 2 ? 'activo':'antes')]">
                  <h3 class="mb-5">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M22,3H2A2,2 0 0,0 0,5V19A2,2 0 0,0 2,21H22A2,2 0 0,0 24,19V5A2,2 0 0,0 22,3M22,19H2V5H22V19M19,18L21,16L19.5,14H17.85C17.63,13.37 17.5,12.7 17.5,12C17.5,11.3 17.63,10.63 17.85,10H19.5L21,8L19,6C17.7,7 16.73,8.38 16.28,10C16.1,10.64 16,11.31 16,12C16,12.69 16.1,13.36 16.28,14C16.73,15.61 17.7,17 19,18M9,12A3,3 0 0,0 12,9A3,3 0 0,0 9,6A3,3 0 0,0 6,9A3,3 0 0,0 9,12M9,8A1,1 0 0,1 10,9A1,1 0 0,1 9,10A1,1 0 0,1 8,9A1,1 0 0,1 9,8M15,16.59C15,14.09 11.03,13 9,13C6.97,13 3,14.09 3,16.59V18H15V16.59M5.5,16C6.22,15.5 7.7,15 9,15C10.3,15 11.77,15.5 12.5,16H5.5Z" />
                    </svg>
                     <span class="ms-3 ">Datos de Contacto</span> 
                  </h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa tu nombre completo</p>
                        <div class="input" :class="{'error' :errores.nombre}">
                          <input type="text" v-model="mnombre">
                        </div>
                        <div class="erroresf" v-if="errores.nombre">
                          <small>Ingresa tu nombre completo.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa tu télefono</p>
                        <div class="input" :class="{'error' :errores.telefono}">
                          <input type="text" v-mask="'(###) ### ####'" v-model="mtelefono">
                        </div>
                        <div class="erroresf" v-if="errores.telefono">
                          <small>Ingresa un teléfono válido a 10 dígitos.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa un correo electrónico</p>
                        <div class="input" :class="{'error' :errores.correo}">
                          <input type="text" v-model="mcorreo">
                        </div>
                        <div class="erroresf" v-if="errores.correo">
                          <small>Ingresa un correo válido.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="paso paso3"  :class="[(step > 3 && step !=3 ?  'despues':''),(step == 3 ? 'activo':'antes')]">
                  <h3 class="mb-5">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M16 11.5C16 10.12 17.12 9 18.5 9S21 10.12 21 11.5 19.88 14 18.5 14 16 12.88 16 11.5M13 3V20H24V3H13M22 16C20.9 16 20 16.9 20 18H17C17 16.9 16.11 16 15 16V7C16.11 7 17 6.11 17 5H20C20 6.11 20.9 7 22 7V16M7 6C8.1 6 9 6.9 9 8S8.1 10 7 10 5 9.1 5 8 5.9 6 7 6M7 4C4.79 4 3 5.79 3 8S4.79 12 7 12 11 10.21 11 8 9.21 4 7 4M7 14C3.13 14 0 15.79 0 18V20H11V18H2C2 17.42 3.75 16 7 16C8.83 16 10.17 16.45 11 16.95V14.72C9.87 14.27 8.5 14 7 14Z" />
                    </svg>
                     <span class="ms-3 ">Datos de Cuenta Bancaria</span> 
                  </h3>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Selecciona tu banco</p>
                        <div class="input" :class="{'error' :errores.banco}">
                          <select v-model="mbanco">
                             <option v-for="item in bancos" :key="item.id" :value="item.name">{{item.name}}</option>
                          </select>
                        </div>
                        <div class="erroresf" v-if="errores.banco">
                          <small>Selecciona un banco.</small>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa tu número de cuenta</p>
                        <div class="input"  :class="{'error' :errores.numc}">
                          <input type="text"  v-model="mnumcuenta">
                        </div>
                        <div class="erroresf" v-if="errores.numc">
                          <small>Ingresa un número de cuenta válido.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Ingresa la CLABE bancaria</p>
                        <div class="input" :class="{'error' :errores.clabe}">
                          <input type="text" v-model="mclabe" >
                        </div>
                        <div class="erroresf" v-if="errores.clabe">
                          <small>Ingresa un correo válido.</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Constancia de Situación Fiscal: <br> <small style="font-weight: bold;">*Fecha de expedición no mayor a 3 meses.</small> </p>
                        <div class="input" :class="{'error' :errores.constancia}">
                          <input type="file" @change="asignarConstancia" accept=".pdf">
                        </div>
                        
                        <div class="erroresf" v-if="errores.constancia">
                          <small>Selecciona un archivo válido (.pdf).</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Régimen Fiscal <br><small>&nbsp;.</small> </p>

                        <div class="input" :class="{'error' :errores.regimen}">
                          <select v-model="mregimen">
                             <option v-for="item in regimenes" :key="item.id" :value="item.codigo">[{{item.codigo}}] - {{item.name}}</option>
                          </select>
                        </div>
                        <div class="erroresf" v-if="errores.regimen">
                          <small>Selecciona un régimen.</small>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="grupo-input">
                        <p>Carátula Estado de Cuenta* <br> <small style="font-weight: bold;">Únicamente archivos (.pdf, .png, .jpg, .jpeg).</small></p>
                        <div class="input" :class="{'error' :errores.estadocuenta}">
                          <input type="file" @change="asignarEstadoCuenta" accept="application/pdf, image/*">
                        </div>
                        <div class="erroresf" v-if="errores.estadocuenta">
                          <small>Selecciona un archivo válido (.pdf, .png, .jpg, .jpeg).</small>
                        </div>
                      </div>
                    </div>
                  </div>
                  
                </div>
                <div class="paso paso4"  :class="[(step > 4 && step !=4 ?  'despues':''),(step == 4 ? 'activo':'antes')]">
                  <h3 class="mb-5">
                    <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                        <path fill="currentColor" d="M5.8 10C5.4 8.8 4.3 8 3 8C1.3 8 0 9.3 0 11S1.3 14 3 14C4.3 14 5.4 13.2 5.8 12H7V14H9V12H11V10H5.8M3 12C2.4 12 2 11.6 2 11S2.4 10 3 10 4 10.4 4 11 3.6 12 3 12M16 4C13.8 4 12 5.8 12 8S13.8 12 16 12 20 10.2 20 8 18.2 4 16 4M16 10.1C14.8 10.1 13.9 9.2 13.9 8C13.9 6.8 14.8 5.9 16 5.9C17.2 5.9 18.1 6.8 18.1 8S17.2 10.1 16 10.1M16 13C13.3 13 8 14.3 8 17V20H24V17C24 14.3 18.7 13 16 13M22.1 18.1H9.9V17C9.9 16.4 13 14.9 16 14.9C19 14.9 22.1 16.4 22.1 17V18.1Z" />
                    </svg>
                     <span class="mr-3 "> Defina la <strong>contraseña</strong> de su cuenta</span> 
                  </h3>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="grupo-input">
                        <p>Ingresa Contraseña</p>
                        <div class="input password">
                            <input :type="verPass ? 'text' : 'password'" v-model="mcontrasena" @keyup="validarPass">
                            <div class="icon">
                              <svg @click="VerContra(1)" v-if="verPass == false" style="width:24px;height:24px" viewBox="0 0 24 24" class="eyePass">
                                  <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                              </svg>
                              <svg @click="VerContra(1)" v-if="verPass == true" style="width:24px;height:24px" viewBox="0 0 24 24" class="eyePass">
                                  <path fill="currentColor" d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z" />
                              </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="grupo-input">
                        <p>Ingresa Confirmación de Contraseña</p>
                        <div class="input password">
                          <input :type="verPass2 ? 'text' : 'password'" v-model="mconfirmac" @keyup="confirmarPass">
                          <div class="icon">
                            <svg @click="VerContra(2)" v-if="verPass2 == false" style="width:24px;height:24px" viewBox="0 0 24 24" class="eyePass">
                                <path fill="currentColor" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" />
                            </svg>
                            <svg @click="VerContra(2)" v-if="verPass2 == true" style="width:24px;height:24px" viewBox="0 0 24 24" class="eyePass">
                                <path fill="currentColor" d="M2,5.27L3.28,4L20,20.72L18.73,22L15.65,18.92C14.5,19.3 13.28,19.5 12,19.5C7,19.5 2.73,16.39 1,12C1.69,10.24 2.79,8.69 4.19,7.46L2,5.27M12,9A3,3 0 0,1 15,12C15,12.35 14.94,12.69 14.83,13L11,9.17C11.31,9.06 11.65,9 12,9M12,4.5C17,4.5 21.27,7.61 23,12C22.18,14.08 20.79,15.88 19,17.19L17.58,15.76C18.94,14.82 20.06,13.54 20.82,12C19.17,8.64 15.76,6.5 12,6.5C10.91,6.5 9.84,6.68 8.84,7L7.3,5.47C8.74,4.85 10.33,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C12.69,17.5 13.37,17.43 14,17.29L11.72,15C10.29,14.85 9.15,13.71 9,12.28L5.6,8.87C4.61,9.72 3.78,10.78 3.18,12Z" />
                            </svg>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12">
                      <small>
                        La contraseña debe contener:
                        <ul>
                          <li :class="{'errorespass' :errores.contrasena.caracters,}"> Al menos 6 carácteres.</li>
                          <li :class="{'errorespass' :errores.contrasena.mayuscula,}"> Al menos una mayúscula.</li>
                          <li :class="{'errorespass' :errores.contrasena.numero,}"> Al menos un número.</li>
                        </ul>
                      </small>
                    </div>
                    <div class="col-md-12 erroresf" v-if="errores.contrasena.confirmacion">
                      <small>
                        Las contraseñas no coinciden.
                      </small>
                    </div>
                    <div class="col-md-12" >
                      <div class="terminos">
                                <p>Al dar clic en REGISTRARME aceptas nuestro <router-link to="/aviso-de-privacidad">aviso de privacidad</router-link> , y utilizar medios electrónicos de identificación, tales como el código de verificación.</p>
                      </div>
                    </div>
                  </div>
                  
                  
                </div>
                <div class="paso paso5"  :class="[(step > 5 && step !=5 ?  'despues':''),(step == 5 ? 'activo':'antes')]">
                  <div class="spinner">
                    <Loading/>
                  </div>
                </div>
                <div class="paso paso5" v-if="step==6">
                  <div class="final-registro">
                    <h2>Tu cuenta ha sido creada con éxito</h2>
                    <p>Ahora solo espera a que se active, <br>descuida nosotros nos encargamos de eso.</p>
                    <router-link to="/"> <button class="btn-credix">Regresar al inicio</button></router-link>
                  </div>
                </div>
                <div class="paso paso6" v-if="step==7">
                  <div class="final-registro">
                    <h2>Lo sentimos ocurrio un error.</h2>
                    <p>{{erroresAxios}}</p>
                   <button @click="step=1" class="btn-credix">Regresar nuevamente</button>
                  </div>
                </div>

                <div class="controlForm">
                  <button class="btn-credix-outline" @click="step--" :disabled="step==1" v-if="step<5">Atrás</button>
                  <button class="btn-credix-outline" @click="siguiente(step)" v-if="step<4">Siguiente</button>
                  <button class="btn-credix-outline"  :disabled="!puedeEnviar" @click="enviarDatos" v-if="step==4" >Registrarme</button>
                </div>
              </div>
          </div>
          <div class="footerform">
              <div class="step " :class="{'active':step>=1}">
                <div class="icon">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M22,3H2C0.91,3.04 0.04,3.91 0,5V19C0.04,20.09 0.91,20.96 2,21H22C23.09,20.96 23.96,20.09 24,19V5C23.96,3.91 23.09,3.04 22,3M22,19H2V5H22V19M14,17V15.75C14,14.09 10.66,13.25 9,13.25C7.34,13.25 4,14.09 4,15.75V17H14M9,7A2.5,2.5 0 0,0 6.5,9.5A2.5,2.5 0 0,0 9,12A2.5,2.5 0 0,0 11.5,9.5A2.5,2.5 0 0,0 9,7M14,7V8H20V7H14M14,9V10H20V9H14M14,11V12H18V11H14" />
                  </svg>
                </div>
              </div>
              <div class="step" :class="{'active':step>=2}">
                <div class="icon">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24" >
                      <path fill="currentColor" d="M11 9C11 10.66 9.66 12 8 12C6.34 12 5 10.66 5 9C5 7.34 6.34 6 8 6C9.66 6 11 7.34 11 9M14 20H2V18C2 15.79 4.69 14 8 14C11.31 14 14 15.79 14 18M22 12V14H13V12M22 8V10H13V8M22 4V6H13V4Z" />
                  </svg>
                </div>
              </div>
              <div class="step" :class="{'active':step>=3}">
                <div class="icon">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M20,8H4V6H20M20,18H4V12H20M20,4H4C2.89,4 2,4.89 2,6V18A2,2 0 0,0 4,20H20A2,2 0 0,0 22,18V6C22,4.89 21.1,4 20,4M7,22H9V24H7V22M11,22H13V24H11V22M15,22H17V24H15V22Z" />
                  </svg>
                </div>
              </div>
              <div class="step " :class="{'active':step>=4}">
                <div class="icon">
                  <svg style="width:24px;height:24px" viewBox="0 0 24 24">
                      <path fill="currentColor" d="M12.67 13.67C12.2 14.13 11.63 14.5 11 14.73V23H8V21H5V18H8V14.72C6.26 14.1 5 12.46 5 10.5C5 8 7 6 9.5 6C9.54 6 9.57 6 9.6 6C9.13 6.95 8.92 8 9.03 9.08C8.44 9.28 8 9.84 8 10.5C8 11.33 8.67 12 9.5 12C9.73 12 9.95 11.94 10.15 11.85C10.79 12.69 11.67 13.32 12.67 13.67M20.73 19.44L17.97 20.6L17.19 18.76L14.43 19.93L13.26 17.16L16.03 16L14.76 13C12.91 13.08 11.11 12.05 10.35 10.25C9.39 7.96 10.47 5.32 12.76 4.35C13 4.25 13.26 4.18 13.5 4.12C12.84 2.87 11.5 2 10 2C7.79 2 6 3.79 6 6C6 6.08 6 6.16 6 6.24C5.7 6.5 5.4 6.82 5.15 7.15C5.06 6.78 5 6.4 5 6C5 3.24 7.24 1 10 1S15 3.24 15 6C15 7.42 14.4 8.67 13.45 9.57C13.87 10 14.5 10.13 15.08 9.88C15.85 9.56 16.2 8.68 15.88 7.92C15.85 7.83 15.8 7.74 15.74 7.66C15.9 7.13 16 6.58 16 6C16 5.37 15.9 4.76 15.72 4.19C17 4.55 18.1 5.44 18.65 6.76C19.41 8.56 18.89 10.57 17.5 11.81L20.73 19.44M13 8.6C13.37 8.19 13.65 7.71 13.82 7.18C13.28 7.45 12.97 8 13 8.6Z" />
                  </svg>
                </div>
              </div>
          </div>
        </div>
      </div>
  </section>
  </div>
</template>

<script>
import Loading from "@/components/Loading/provedor.vue"
import axios from "axios"
export default {
  
  mounted:function(){
    axios.get('https://credix.com.mx/api/front/bancos.php')
      .then((res)=>{
          this.bancos = res.data
      })
      .catch((err)=>{
        console.log(err)
      });
    axios.get('https://credix.com.mx/api/front/regimenes.php')
    .then(res=>{
      this.regimenes = res.data
    })
    .catch(err =>{
      console.log(err)
    })
  },
  components: {
      Loading
    },
  data:()=>({
    step:1,
    verPass:false,
    verPass2:false,
    bancos:[],
    regimenes:[],
    mrfc:"",
    mcurp:"",
    mrs:"",
    mcalle:"",
    mnext:"",
    mnint:"",
    mcp:"",
    mlocalidad:"",
    mmuni:"",
    mestado:"",
    mpais:"",
    mnombre:"",
    mtelefono:"",
    mcorreo:"",
    mbanco:"",
    mnumcuenta:"",
    mclabe:"",
    mfileconstancia:null,
    mfileestadocuenta:null,
    mcontrasena:"",
    mregimen:null,
    mconfirmac:"",
    errores:{
      rfc:false,
      curp:false,
      rs:false,
      calle:false,
      next:false,
      nint:false,
      cp:false,
      loc:false,
      muni:false,
      estado:false,
      pais:false,
      nombre:false,
      telefono:false,
      correo:false,
      banco:false,
      numc:false,
      clabe:false,
      constancia:false,
      regimen:false,
      estadocuenta:false,
      contrasena:{
        caracters:false,
        mayuscula:false,
        numero:false,
        confirmacion:false
      }
    },
    puedeEnviar:false,
    erroresAxios:[]
  }),
  computed:{
    stepperProgress(){
      return (100/3) * (this.step - 1) + "%"
    },
   isComplete () {
            return (
                this.confirmarPass && this.validarPass
        )
    }
  },
  methods:{
    siguiente(form){
      if(form==1){
        this.errores.rfc = (this.mrfc.length < 12)?true:false
        this.errores.rs = (this.mrs == "")?true:false
        this.errores.calle = (this.mcalle == "")?true:false
        this.errores.next = (this.mnext == "")?true:false
        this.errores.cp = (this.mcp.length<5)?true:false
        this.errores.loc = (this.mlocalidad =="")?true:false
        this.errores.muni = (this.mmuni=="")?true:false
        this.errores.estado = (this.mestado =="")?true:false
        this.errores.pais = (this.mpais =="")?true:false
        if(!this.errores.rfc && !this.errores.rs && !this.errores.calle && !this.errores.next && !this.errores.cp && !this.errores.loc && !this.errores.muni  && !this.errores.estado && !this.errores.pais){
          this.step++
        }
      }
      if(form==2){
        this.errores.nombre = (this.mnombre.length < 1)?true:false
        this.errores.telefono = (this.mtelefono.length < 10)?true:false
        this.errores.correo = (!this.validarEmail(this.mcorreo))?true:false
        if(!this.errores.nombre && !this.errores.telefono && !this.errores.correo){
          this.step++
        }
      }
      if(form==3){
        this.errores.banco = (this.mbanco =="")?true:false
        this.errores.numc = (this.mnumcuenta == "")?true:false
        this.errores.clabe = (this.mclabe == "")?true:false
        this.errores.constancia = (this.mfileconstancia == null)?true:false
        this.errores.estadocuenta = (this.mfileestadocuenta == null)?true:false
        if(!this.errores.banco && !this.errores.numc && !this.errores.clabe && !this.errores.constancia && !this.errores.estadocuenta ){
          this.step++
        }
      }
      
    },
    validarEmail(email){
      return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    },
    asignarConstancia(event){
      this.mfileconstancia = event.target.files[0]
    },
    asignarEstadoCuenta(event){
      this.mfileestadocuenta = event.target.files[0]
    },
    validarPass(){
        this.errores.contrasena.caracters = (this.mcontrasena.length<6)?true:false
        this.errores.contrasena.mayuscula = (!this.mcontrasena.match(/[A-Z]/))?true:false
        this.errores.contrasena.numero = (!this.mcontrasena.match(/\d/))?true:false
        if(this.mconfirmac.length){
          this.confirmarPass
        }
        return (!this.errores.contrasena.caracters && !this.errores.contrasena.mayuscula && !this.errores.contrasena.numero)?true:false
        
    },
    confirmarPass(){
      if(this.mconfirmac.length){
        if(this.mcontrasena != this.mconfirmac){
            this.errores.contrasena.confirmacion = true;
        }else{
          this.errores.contrasena.confirmacion = false;
          if(this.validarPass()){
            this.puedeEnviar = true 
          }
          
        }
      }else{
        this.puedeEnviar = false
      }
      
      
    },
    
    enviarDatos(){
      if(this.puedeEnviar){
        var formProv = new FormData()
        formProv.append("btnRegistrarse","Registrarse")
        formProv.append("RFC",this.mrfc)
        formProv.append("CURP",this.mcurp)
        formProv.append("strRazonSocial",this.mrs)
        formProv.append("strCalle",this.mcalle)
        formProv.append("strNumExt",this.mnext)
        formProv.append("strNumInt",this.mnint)
        formProv.append("strCp",this.mcp)
        formProv.append("strLocalidad",this.mlocalidad)
        formProv.append("strMunicipio",this.mmuni)
        formProv.append("strEstado",this.mestado)
        formProv.append("strPais",this.mpais)
        formProv.append("strContacto",this.mnombre)
        formProv.append("strTelefono",this.mtelefono)
        formProv.append("strEmail",this.mcorreo)
        formProv.append("strBanco",this.mbanco)
        formProv.append("strCuenta",this.mnumcuenta)
        formProv.append("strClabe",this.mclabe)
        formProv.append("archivoRFC",this.mfileconstancia,this.mfileconstancia.name)
        formProv.append("archivoCuenta",this.mfileestadocuenta,this.mfileestadocuenta.name)
        formProv.append("strpw",this.mcontrasena)
        formProv.append("strpw2",this.mconfirmac)
        formProv.append("regimenFiscal",this.mregimen);
        formProv.append("key",this.getKey())
        this.step++;
        axios.post('https://credix.com.mx/api/front/registrarProveedor.php',formProv)
        .then((res)=>{
          //console.log(res);
          this.erroresAxios = res.data.descripcion
          if(res.data.code == 1){
            setTimeout(() => {
              this.step = 6
            }, 5000);
          }else{
            this.step = 7
          }
        })
        .catch((err)=>{
          alert("Se ha producido un error: "+err)
          this.step=1
        })
      }
    },
    VerContra(el){
      if(el == 1){
        this.verPass = !this.verPass
      }
      if(el == 2){
        this.verPass2 = !this.verPass2
      }
    },
    getKey(){
      var crypto = require('crypto')
      var shasum = crypto.createHash('sha1')
      var dateObj = new Date();
      var month = String(dateObj.getMonth() + 1).padStart(2, '0');
      var day = String(dateObj.getDate()).padStart(2, '0');
      var year = dateObj.getUTCFullYear();
      var completeKey = "credix"+ year + month  + day;
      shasum.update(completeKey)
      let key = shasum.digest('hex')
      return key
    }
  }
}
</script>

<style lang="css" scoped>
.erroresf small{
  color: #d00524;
  font-weight: 600;
}


.errorespass{
  color:#d00524;
  font-weight: 700;
}

.header3 {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 0.5rem 1rem;
}
.terminos {
  margin: 10px 0;
}
.terminos p{
  font-size: 9pt;
  color: #000;
  opacity: 0.4;
}
.terminos p a{
  color: #006699;
  font-weight: 600;
}
.header3 .imgLogo{
  margin-left: 2rem;
}
.final-registro {
  color: #006699;
}
.container-form {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}
.container-form .formulario {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 4rem;
}
.container-form .formulario {
  height: 90%;
}
.container-form .footerform {
  display: flex;
  justify-content: center;
  height: 10%;
  box-shadow: 0px -1px 8px 1px #00669966;
}
.container-form .footerform .step {
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-form .footerform .step .icon {
  border: 1px solid #006699;
  padding: 9px 9px;
  border-radius: 50px;
  color: #006699;
}
.container-form .footerform .step.active .icon {
  color: #fff;
  background-color: #006699;
}
.container-form .icon {
  color: #006699;
}
.container-form .input {
  border-bottom: 1px solid #006699;
  border-radius: 10px;
  
  padding: 10px 20px;
  display: flex;
}
.container-form input {
  width: 100%;
  border: none;
  background: none;
  letter-spacing: 2px;
}
select{
  background: none;
}
.container-form input:focus-visible {
  border: none;
  outline: none;
}
.form-prov {
  width: 100%;
  background: #006699;
  height: 100vh;
  display: flex;
}
.form-prov .sec-left {
  width: 30%;
  height: 100%;
  color: #fff;
  background-color: #006699;
  display: flex;
  flex-direction: column;
  padding: 0 60px;
  justify-content: center;
  align-items: center;
}
.form-prov .sec-left .itemben {
  display: flex;
  margin: 1rem 0;
}
.form-prov .sec-left .itemben h5 {
  margin-left: 20px;
  font-weight: lighter;
}
.form-prov .sec-rig {
  width: 70%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.form-prov select {
  width: 100%;
  border: none;
  outline: none;
}
.form-prov small {
  font-size: 9px;
  font-style: italic;
  margin: 0;
}
.form-prov input[type=file] {
  width: 100%;
  font-size: 10px;
  letter-spacing: 0;
}
.grupo-input .input.error{
  background-color: #d0022224;
}
.grupo-input {
  margin-bottom: 1rem;
}
.grupo-input a {
  text-decoration: none;
  color: #006699;
}
.grupo-input p {
  margin-bottom: 0px;
}
.grupo-input .forget {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.pasos {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
}
.pasos .paso {
  overflow: hidden;
}
.pasos .paso h3 {
  font-weight: 300;
  color: #006699;
}
.pasos .paso.activo {
  position: relative;
  width: 100%;
  height: 70%;
  padding-top: 0px;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
}
.pasos .paso.antes {
  position: absolute;
  width: 100%;
  line-height: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
  height: 0%;
  padding-top: 0px;
  pointer-events: none;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  opacity: 0;
  transform: translateY(100vh);
}
.pasos .paso.despues {
  position: absolute;
  width: 100%;
  height: 70%;
  padding-top: 0px;
  pointer-events: none;
  transition: transform 600ms ease 0s, opacity 300ms ease 0s;
  opacity: 0;
  transform: translateY(-100vh);
}
.controlForm {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}
.controlForm button {
  margin-left: 15px;
}
.controlForm button:disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.eyePass {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .header3 {
    width: 100%;
    padding: 1rem 1rem;
    background-color: white;
    z-index: 99;
  }
  .form-prov {
    padding-top: 3rem;
    height: 100%;
  }
  .form-prov .sec-left {
    display: none;
  }
  .form-prov .sec-rig {
    width: 100%;
  }
  .form-prov .sec-rig .container-form {
    display: block;
  }
  .form-prov .sec-rig .container-form .formulario {
    padding: 1.3rem 1.5rem;
    height: 100%;
    display: block;
  }
  .form-prov .sec-rig .container-form .formulario .pasos {
    display: block;
    margin-bottom: 20rem;
    height: auto;
  }
  .form-prov .sec-rig .container-form .formulario .pasos .paso.activo {
    height: 100%;
  }
  .form-prov .sec-rig .container-form .footerform {
    padding: 10px 0;
    z-index: 999;
    position: fixed;
    bottom: 0px;
    display: block;
    left: 0;
    background-color: #fff;
    width: 100%;
  }
  .form-prov .sec-rig .container-form .footerform .step {
    float: left;
    text-align: center;
    margin: 0;
    width: 25%;
  }
  .header3 .imgLogo{
  margin-left: 0rem;
}
}

</style>