import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeRoutes from './HomeRoutes'
import LoginRoutes from './LoginRoutes'
//

Vue.use(VueRouter)

const routes = [
  ...HomeRoutes,
  ...LoginRoutes
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
 }  
})

export default router
